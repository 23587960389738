//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import "~/node_modules/vue-slick-carousel/dist/vue-slick-carousel.css";
import 'radar-sdk-js/dist/radar.css';

  export default {
    data() {
    return {
      query: "",
    };
  },
  mounted() {
    // Initialize Radar SDK
    this.$radar.initialize(process.env.RADAR_KEY)

    // // Create autocomplete widgets
    this.$radar.ui.autocomplete({
      container: 'autocomplete', // OR document.getElementById('autocomplete')
      responsive: true,
      placeholder: 'Enter Your Address',
      countryCode: 'US',
      onSelection: (result) => {
        this.query = result.addressLabel
        this.search()
      },
    })

    // Add autofill functionality
    this.$nextTick(() => {
      document.addEventListener('keyup', (e) => {
        if (e.target.parentElement.parentElement.id == 'autocomplete') {
          this.query = e.target.value
        }
      })
    })
  },
  methods: {
    search() {
      this.$router.push("/locations?address=" + this.query);
    }
  }
  }
